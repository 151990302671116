import React, { useRef, useEffect, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon, ErrorMessage } from 'components'
import { initSelectpicker, formikPropTypes } from './utils'

const defaultIcon = {}

const Select = ({
  name = '',
  field,
  formik,
  error = '',
  icon = defaultIcon,
  options,
  multiple = false,
  disabled = false,
  ...rest
}) => {
  const selectRef = useRef()

  useEffect(() => { initSelectpicker(selectRef.current) }, [])

  useEffect(() => { $(selectRef.current).selectpicker('refresh') })

  const onChange = useCallback(({ target: { selectedOptions } }) => {
    const value = Array.from(selectedOptions).map(option => option.value)
    formik.setFieldValue(field, multiple ? value : value[0])
  }, [field, multiple])

  const inputClasses = classNames('form-control', { 'is-invalid': Boolean(error) })

  return (
    <div className="form-group row">
      <div className="col-1 col-form-label">
        <Icon name={icon.name} title={icon.title} />
      </div>
      <div className="col-11">
        <select
          name={name}
          className={inputClasses}
          ref={selectRef}
          value={formik.values[field]}
          disabled={disabled}
          multiple={multiple}
          onChange={onChange}
          {...rest} // eslint-disable-line react/jsx-props-no-spreading
        >
          {options.map(({ key, value }) => (
            <option key={key} value={value}>{key}</option>
          ))}
        </select>
        {error ? <ErrorMessage message={error} /> : null}
      </div>
    </div>
  )
}

Select.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  field: PropTypes.string.isRequired,
  formik: formikPropTypes.isRequired,
  icon: PropTypes.exact({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  multiple: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
}

const isMemoizable = (prev, next) =>
  prev.disabled === next.disabled &&
  prev.formik.values[prev.field] === next.formik.values[next.field] &&
  prev.formik.errors[prev.field] === next.formik.errors[next.field]


export default memo(Select, isMemoizable)