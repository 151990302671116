import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { AjaxSelect, InputDate, Input, Select, ErrorCard } from 'components'
import { formikPropTypes } from 'components/form/utils'

const AppointmentForm = ({ formik, isFromThePast = false }) => {
  const authenticityToken = $('meta[name=csrf-token]').attr('content')

  const recipientOptions = [formik.values.recipient].map(user =>
    user ? { key: user.id, value: `${user.first_name} ${user.last_name}` } : null
  ).filter(Boolean)

  const serviceOptions = [formik.values.service].map(service =>
    service ? { key: service.id, value: service.name } : null
  ).filter(Boolean)

  const participantOptions = formik.values.participants.map(participant => (
    { key: participant?.id, value: participant?.name }
  )).filter(Boolean)

  const channelOptions = [
    { key: 'Email', value: 'email' },
    { key: 'SMS', value: 'sms' },
    { key: 'Impression PDF', value: 'letter' }
  ]

  const cancellableCallback = useCallback(options => {
    formik.setFieldValue('cancellable', options[0].getAttribute('data-cancellable'))
    formik.setFieldValue('cancellationDelay', options[0].getAttribute('data-cancellation_delay'))
  })

  return (
    <>
      {formik.values.errorMessage ? <ErrorCard message={formik.values.errorMessage} /> : null}
      <AjaxSelect
        name="appointment[service_id]"
        field="serviceId"
        formik={formik}
        options={serviceOptions}
        error={formik.errors.serviceId}
        url="/services.json"
        title="Service"
        icon={{ name: 'exchange-alt', title: 'Service du rendez-vous' }}
        callback={cancellableCallback}
        disabled={isFromThePast}
      />
      <AjaxSelect
        name="appointment[recipient_id]"
        field="recipientId"
        formik={formik}
        options={recipientOptions}
        error={formik.errors.recipientId}
        url="/contacts.json"
        title="Destinataire"
        icon={{ name: 'address-book', title: 'Destinataire du rendez-vous' }}
        disabled={isFromThePast}
      />
      <InputDate
        name="appointment[start_at]"
        field="startAt"
        formik={formik}
        error={formik.errors.startAt}
        placeholder="Date et heure de début..."
        icon={{ name: 'calendar', title: 'Date et heure de début du rendez-vous' }}
      />
      <InputDate
        name="appointment[end_at]"
        field="endAt"
        formik={formik}
        error={formik.errors.endAt}
        placeholder="Date et heure de fin..."
        icon={{ name: 'calendar', title: 'Date et heure de fin du rendez-vous' }}
      />
      <Input
        name="appointment[location]"
        field="location"
        formik={formik}
        placeholder="Emplacement"
        icon={{ name: 'map-marked-alt', title: 'Emplacement du rendez-vous' }}
        disabled={isFromThePast}
      />
      <AjaxSelect
        name="appointment[participant_ids][]"
        field="participantIds"
        formik={formik}
        options={participantOptions}
        url="/users"
        title="Participants supplémentaires"
        icon={{ name: 'users', title: 'Participants supplémentaires (optionnel)' }}
        disabled={isFromThePast}
        multiple
      />
      <Select
        name="appointment[channels]"
        field="channels"
        formik={formik}
        options={channelOptions}
        icon={{ name: 'bell', title: 'Canal de notification du destinataire' }}
        disabled={isFromThePast}
        multiple
      />
      <Input
        name="appointment[reminder_delay]"
        field="reminderDelay"
        formik={formik}
        type="number"
        formClasse="mb-0"
        min="0"
        max="168"
        icon={{ name: 'user-clock', title: 'Rappel du destinataire (H-x, 0 = désactivé)' }}
        placeholder="Rappel du destinataire"
        disabled={isFromThePast}
      />
      <input type="hidden" name="user" value={formik.values.userId} />
      <input type="hidden" name="authenticity_token" value={authenticityToken} />
    </>
  )
}

AppointmentForm.propTypes = {
  formik: formikPropTypes.isRequired,
  isFromThePast: PropTypes.bool
}

export default AppointmentForm
