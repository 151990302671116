const headers = { 'x-csrf-token': $('meta[name=csrf-token]').attr('content') }

const formatAppointmentData = data => ({
  params: {
    start_date: moment(data.date).startOf('month').toISOString(),
    end_date: moment(data.date).endOf('month').toISOString(),
    calendar: data.calendar,
    user_id: data.userId,
    recipient_ids: data.recipient_ids?.join('-'),
    service_ids: data.service_ids?.join('-')
  }
})

const formatFormAppointmentData = data => (
  {
    user_id: data.userId,
    appointment: {
      service_id: data.serviceId,
      recipient_id: data.recipientId,
      start_at: moment(data.startAt).toISOString(),
      end_at: moment(data.endAt).toISOString(),
      location: data.location,
      participant_ids: data.participantIds,
      channels: data.channels,
      reminder_delay: data.reminderDelay,
      skip_notification: `${moment(data.startAt).isBefore()}`,
      cancellable: data.cancellable,
      cancellation_delay: data.cancellationDelay
    }
  })

const formatAppointmentSchedulesData = data => ({
  appointment: {
    start_at: moment(data.startAt).toISOString(),
    end_at: moment(data.endAt).toISOString()
  }
})

export {
  headers,
  formatAppointmentData,
  formatFormAppointmentData,
  formatAppointmentSchedulesData
}
