import React, { useEffect, useContext, useRef, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { setLocale, object, string } from 'yup'
import { Modal, ModalHeader } from 'components'
import { AgendaContext } from 'pages/agenda/contexts'
import { strDate } from 'pages/agenda/utils/date'
import { createAppointment } from 'pages/agenda/actions'
import { fr } from 'locales/yup'
import { AppointmentForm } from '.'

const NewAppointment = () => {
  const navigate = useNavigate()
  const modalRef = useRef()
  const [validateOnChange, setValidateOnChange] = useState(false)
  const { currentUserId } = useContext(AgendaContext)
  const { state } = useLocation()
  const { userId = currentUserId, newDate } = state || {}

  const closeModal = useCallback(() => {
    $(modalRef.current).modal('hide')
    delete state.newDate
    navigate('/agenda', { state })
  }, [modalRef, state])

  useEffect(() => {
    setLocale(fr)
    $(modalRef.current).modal('show')
    $(modalRef.current).on('hidden.bs.modal',() => closeModal())
  }, [])

  const initialValues = {
    userId,
    serviceId: '',
    recipientId: '',
    startAt: newDate,
    endAt: strDate(moment(newDate).add(1, 'hour')),
    location: '',
    participants: [],
    participantIds: [],
    channels: [],
    reminderDelay: 0,
    cancellable: false,
    cancellationDelay: 0,
    errorMessage: ''
  }

  const validationSchema = object({
    recipientId: string().label('Le destinataire').required(),
    serviceId: string().label('Le service').required(),
    startAt: string().required(),
    endAt: string().label('La date de fin').required().test(
      (date, { parent: { startAt } }) => moment(startAt).isBefore(date)
    )
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange,
    onSubmit: data => {
      createAppointment(data)
        .then(() => closeModal())
        .catch(({ message }) => formik.setFieldValue('errorMessage', message))
    }
  })

  useEffect(() => {
    if (!formik.isValid)
      setValidateOnChange(true)
  }, [formik.isValid])

  return (
    <Modal modalRef={modalRef}>
      <ModalHeader
        title="Créer un nouveau rendez-vous"
        icon={{ name: 'calendar-alt', title: 'Créer un nouveau rendez-vous' }}
        closeModal={closeModal}
      />
      <form method="post" action="/appointments/customization">
        <div className="modal-body">
          <AppointmentForm formik={formik} />
        </div>
        <div className="modal-footer">
          { formik.values.channels.length === 0 ? '' : (
            <button className="btn btn-primary mr-1" type="submit">
              Modifier les notifications
            </button>
          )}
          <button
            className="btn btn-primary"
            type="button"
            disabled={!formik.isValid}
            onClick={formik.handleSubmit}
          >
            Envoyer
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default NewAppointment
